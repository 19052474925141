
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import PublicGenericFormat from './PublicGenericFormat.vue'
import PublicBookFormat from './PublicBookFormat.vue'
import { Publication, PublicationType } from '../types'
import { Results } from '@/global-types'
import { updateMathJax } from '@/utils/utils'

@Component({
  components: {
    PublicGenericFormat,
    PublicBookFormat,
  },
})
export default class PublicResultsComponent extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ required: true }) publications!: Results<Publication>

  @PropSync('currentPage', { type: Number }) syncedCurrentPage!: number
  @PropSync('pageSize', { type: Number }) syncedPageSize!: number

  //
  // REACTIVE PROPERTIES
  //
  batchOpened = false
  checkAll = false
  pageSizes = [5, 10, 20, 50, 100]
  checkboxStatus = [false]
  selectedMRNumbers : number[] = []
  PublicationType = PublicationType
  dismissCountDown = 0

  //
  // COMPUTED PROPERTIES
  //

  get totalPaginationRows() {
    return this.publications.total > 1000 ? 1000 : this.publications.total
  }

  //
  // LIFECYCLE HOOKS
  //
  mounted() {
    updateMathJax()
  }

  updated() {
    updateMathJax()
  }

  updateCurrentPage(currentPage: number) {
    this.syncedCurrentPage = currentPage
  }

  updateSelectedMRNumbers() {
    let selected = this.checkboxStatus.map((x, index) => {
      if (x) {
        return this.publications.results[index].mrnumber
      } else {
        return -1
      }
    })
    selected = selected.filter(x => x > -1)
    // Set selectedMRNumbers
    this.selectedMRNumbers.splice(0, this.selectedMRNumbers.length)
    this.selectedMRNumbers.push(...selected)
  }

  getMoreResults() {
    this.$emit('more')
    // Append to checkbox
  }
}
