
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Results, emptyResults, PublicSearchParams } from '@/global-types'
import { PublicationsAPI } from '@/api'
import { Publication } from '@/components/search-results/publications-results'
import { updatePublicSearchParams } from '@/utils/utils'
import PublicResultsComponent from '@/components/search-results/publications-results/public/PublicResultsComponent.vue'
import VueRouter from 'vue-router'

const defaultSearchParams: PublicSearchParams = {
  page: 1,
  size: 20,
}

@Component({
  components: {
    PublicResultsComponent,
  },
})
export default class Publications extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  publicationsResults: Results<Publication> = emptyResults
  searchParams: PublicSearchParams = defaultSearchParams
  loadingPublications = false
  concatResults = false

  //
  // WATCHERS
  //
  @Watch('$route', { immediate: true })
  onRouteChange() {
    this.resetData()
    this.updateDataFromURLParams()
    this.fetchPublications()
  }

  //
  // EVENT HANDLERS
  //
  updateCurrentPage(page: number) {
    this.concatResults = false
    this.updateURLParams({ ...this.searchParams, page })
  }

  updateInternalPageSize(size: number) {
    this.updateURLParams({ ...this.searchParams, size })
  }

  getMoreResults() {
    this.concatResults = true
    this.updateURLParams({ ...this.searchParams, page: this.searchParams.page + 1 })
  }

  //
  // METHODS
  //
  resetData() {
    this.searchParams = defaultSearchParams
  }

  updateDataFromURLParams() {
    this.searchParams = updatePublicSearchParams(this.$route, this.searchParams)
  }

  updateRouter(router: VueRouter, replace = false, freetool = false) {
    return (searchParams: PublicSearchParams): void => {
      const info = {
        name: freetool ? 'Publications' : 'PublicationsSearch',
        query: {
          mi: searchParams.mi?.toString(),
          ps: searchParams.ps,
          pc: searchParams.pc,
          sc: searchParams.sc,
          et: searchParams.et,
          mrlistId: searchParams.mrlistId,
          page: searchParams.page.toString(),
          size: searchParams.size.toString(),
        },
      }

      if (replace) {
        router.replace(info)
      } else {
        router.push(info)
      }
    }
  }

  updateURLParams(searchParams: PublicSearchParams, replace?: boolean) {
    const freetool = true
    this.updateRouter(this.$router, replace, freetool)(searchParams)
  }

  async fetchPublications() {
    console.log(this.searchParams)
    this.loadingPublications = true
    let publicationsResults = await PublicationsAPI.searchPublic({
      mi: this.searchParams.mi,
      ps: this.searchParams.ps,
      pc: this.searchParams.pc,
      sc: this.searchParams.sc,
      et: this.searchParams.et,
      mrlistId: this.searchParams.mrlistId,
      page: this.searchParams.page,
      size: this.searchParams.size,
    })

    if (this.concatResults) {
      publicationsResults = {
        results: [...this.publicationsResults.results, ...publicationsResults.results],
        total: this.publicationsResults.total, // + publicationsResults.total,
      }
    }

    this.publicationsResults = publicationsResults

    this.loadingPublications = false
  }
}
