
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Publication, PublicationType } from '../types'
import { parseReview } from '@/utils/utils'
// import { UPDATE_PREV_NEXT_PUB_ID } from '@/store'

@Component
export default class PublicBookFormat extends Vue {
  @Prop() item!: Publication
  //
  // REACTIVE PROPERTIES
  //
  PublicationType = PublicationType

  //
  // COMPONENT PROPERTIES
  //

  get formattedMRNumber() {
    // Add leading zeros if needed
    const mrNumLength = 7
    const leadingZeros = mrNumLength - this.item.mrnumber.toString().length
    return '0'.repeat(leadingZeros) + this.item.mrnumber
  }

  get articleRoute() {
    // return {
    //   name: 'RelayStation',
    //   query: {
    //     // mr: this.item.mrnumber.toString(),
    //     mr: this.formattedMRNumber,
    //   },
    // }
    return `https://mathscinet.ams.org/mathscinet-getitem?mr=${this.formattedMRNumber}`
  }

  get hasArticle() {
    return this.item.articleUrl !== '' || (this.item.openURL && this.item.openURL.targetLink.length > 0)
  }

  get title() {
    return parseReview(this.item.titles.title)
  }

  get translatedTitle() {
    return parseReview(this.item.titles.translatedTitle)
  }

  get displayPaging() {
    // Test with MR0620028
    // return this.clearPunctuation(this.paging.text)
    let text = this.dashReplacement(this.item.paging.paging.text)
    const endChar = text.charAt(text.length - 1)
    if (endChar !== ',' && endChar !== '.') {
      text += '.'
    }

    return text
  }

  dashReplacement(text: string) {
    text = text.replaceAll('---', '&mdash;')
    text = text.replaceAll('--', '&ndash;')
    return text
  }
}
