export enum PublicationType {
  B = 'B',
  BC = 'BC',
  BCZ = 'BCZ',
  J = 'J',
  JC = 'JC',
  JCZ = 'JCZ',
  JT = 'JT'
}

type Author = {
  id: number
  name: string
  institutionCode: string
  institutionName: string
  institutionAddress: string
}

type Journal = {
  id: number
  shortTitle: string
}

type Issue = {
  pubYear: number
  pubYear2: string
  volume: string
  volume1?: string
  volume2?: string
  volume3?: string
  number: string
  journal: Journal
  volSlash: string
  isbn?: string[]
  elementOrd?: string
}

type Series = {
  // id: number
  serId: number
  title: string
  transTitle: string
  volume: string
  shortTitle: string
}

type PublicationPublisher = {
  name: string
  abbr: string
  location: string
  preText: string
  postText: string
}

type Book = {
  pubYear: number
  publisher: PublicationPublisher[]
  isbn: string[]
  series: Series[]
  language: string
}

type Paging = {
  text: string
}

type PublicationCounts = {
  cited: number
}

type OpenURL = {
  imageLink: string
  targetLink: string
  textLink: string
}

type PublicationReviewer = {
  authId: number
  name: string
}

export type PublicationReviewers = {
  public: boolean
  reviewers: PublicationReviewer[]
}

type PublicationPrePubl = {
  review?: string
  reviewer?: PublicationReviewers
}

type PublicationClass = {
  code: string
  description: string
}

export type PublicationTitles = {
  title: string
  translatedTitle: string
}

type PublicationIssue = {
  issue: Issue
  translatedIssue?: Issue
}

type ArticlePagings = {
  paging: Paging
  translatedPaging?: Paging
}

type ReviewerInfo = {
  authId: number
  rvrCode: number
  name: string
}

export type Reviewers = {
  public: boolean
  reviewers: ReviewerInfo[]
}

export type Publication = {
  mrnumber: number
  titles: PublicationTitles
  entryType: PublicationType
  primaryClass: PublicationClass
  authors: Author[]
  issue: PublicationIssue
  book: Book
  reviewer: Reviewers
  paging: ArticlePagings
  counts: PublicationCounts
  itemType: string
  articleUrl: string
  openURL: OpenURL
  prePubl?: PublicationPrePubl
  public?: boolean
}
